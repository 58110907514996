import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  fetchSingleMovie,
  clearSingleMovie,
  imdbUpdateMovie,
  updateSingleMovie,
  createImage,
  deleteImage
} from './../actions';
import { fetchLanguages, fetchGenres } from './../../common/actions';
import { fetchMovieProviders } from './../../movies/actions';
import { Throbber } from './../../common/components/Throbber';
import SingleMovieSettings from './SingleMovieSettings';

class SingleMovieContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.movieID = props.match.params && props.match.params.id;
  }

  componentDidMount() {
    if (!this.props.languages) {
      this.props.fetchLanguages();
    }
    if (!this.props.genres.data) {
      this.props.fetchGenres();
    }
    if (!this.props.movieProviders) {
      this.props.fetchMovieProviders();
    }
    if (this.movieID) {
      this.props.fetchSingleMovie(this.movieID);
    }
  }

  componentWillUnmount() {
    this.props.clearSingleMovie();
  }

  render() {
    return this.props.loading ? (
      <Throbber customClass="large center" />
    ) : (
      <div className="container mb-3">
        <div className="row">
          <SingleMovieSettings
            movie={this.props.movie}
            languages={this.props.languages}
            movieProviders={this.props.movieProviders}
            genres={this.props.genres}
            imdbUpdateMovie={() => this.props.imdbUpdateMovie(this.movieID)}
            updateSingleMovie={params => this.props.updateSingleMovie(this.movieID, params)}
            addImage={this.props.createImage}
            deleteImage={this.props.deleteImage}
            uploadingImage={this.props.uploadingImage}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ commonReducer, moviesReducer, singleMovieReducer }) => {
  return {
    movie: singleMovieReducer.singleMovie.data,
    languages: commonReducer.languages.data,
    genres: commonReducer.genres,
    movieProviders: moviesReducer.movieProviders.data,
    loading:
      commonReducer.languages.loading ||
      commonReducer.genres.loading ||
      moviesReducer.movieProviders.loading ||
      singleMovieReducer.singleMovie.loading,
    uploadingImage: singleMovieReducer.singleMovie.uploading
  };
};

const mapDispatchToProps = {
  fetchSingleMovie,
  clearSingleMovie,
  fetchLanguages,
  fetchGenres,
  fetchMovieProviders,
  imdbUpdateMovie,
  updateSingleMovie,
  createImage,
  deleteImage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleMovieContainer);
