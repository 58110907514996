// NOTIFICATIONS CONFIG
export const NOTIFICATION_DISPLAY_TIME = 5000;

export const NOTIFICATION_TYPE = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success'
};

// NOTIFICATIONS MESSAGES
export const ERROR_CODE_NOT_FOUND = 404;

export const GLOBAL_ERRORS = {
  401: 'Error! Action is unauthorized',
  404: 'Error! Not found',
  500: 'Error! Internal server error'
};
