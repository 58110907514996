import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const handleLanguagesRadio = (e, setValues, values, languages) => {
  setValues({
    ...values,
    language: languages.filter(item => item.id.toString() === e.target.value)[0]
  });
};

export const renderLanguages = ({ props, languages }) => {
  const { values, setValues } = props;
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label htmlFor="languages">Languages:</label>
      </div>
      <div className="form-group col-9">
        {languages &&
          languages.map((item, index) => {
            return (
              <span
                key={item.id}
                className={`custom-radio custom-control d-inline-block ${index !== 0 && 'ml-1'}`}
              >
                <input
                  id={item.id}
                  name="languages"
                  type="radio"
                  value={item.id}
                  onChange={e => handleLanguagesRadio(e, setValues, values, languages)}
                  checked={values.language && values.language.id === item.id}
                  className="custom-control-input"
                />
                <label htmlFor={item.id} className="custom-control-label">
                  {item.name}
                </label>
              </span>
            );
          })}
      </div>
    </div>
  );
};

export const renderInput = (props, name, key) => {
  const { values, errors, touched, handleChange } = props;
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label htmlFor={key}>{name}:</label>
      </div>
      <div className="form-group col-9">
        <input
          id={key}
          placeholder={name}
          type="text"
          value={(values[key] && values[key].toString()) || ''}
          onChange={handleChange}
          className={
            errors[key] && touched[key]
              ? 'text-input form-control error'
              : 'text-input form-control'
          }
        />
        {errors[key] && touched[key] && <div className="input-feedback">{errors[key]}</div>}
      </div>
    </div>
  );
};

export const renderTextarea = (props, name, key) => {
  const { values, errors, touched, handleChange } = props;
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label htmlFor={key}>{name}:</label>
      </div>
      <div className="form-group col-9">
        <textarea
          id={key}
          placeholder={name}
          value={(values[key] && values[key].toString()) || ''}
          onChange={handleChange}
          className={
            errors[key] && touched[key]
              ? 'text-input form-control error'
              : 'text-input form-control'
          }
        />
        {errors[key] && touched[key] && <div className="input-feedback">{errors[key]}</div>}
      </div>
    </div>
  );
};

const handleDatePicker = (date, setValues, values, key) => {
  const timestamp = new Date(date).valueOf() / 1000;
  setValues({
    ...values,
    [key]: timestamp
  });
};

export const renderDatePicker = ({ props, name, key }) => {
  const { values, setValues } = props;
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label>{name}:</label>
      </div>
      <div className="form-group col-9">
        <DatePicker
          selected={values[key] * 1000}
          onChange={date => handleDatePicker(date, setValues, values, key)}
          dateFormat="yyyy-MM-dd"
        />
      </div>
    </div>
  );
};

const updateProviders = (e, setValues, values, providers) => {
  setValues({
    ...values,
    movieProvider: providers.filter(item => item.id.toString() === e.target.value)[0]
  });
};

export const renderProviders = ({ props, providers }) => {
  const { values, setValues } = props;
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label>Movie provider:</label>
      </div>
      <div className="form-group col-9">
        <select
          className="form-control"
          value={(values.movieProvider && values.movieProvider.id) || ''}
          onChange={e => updateProviders(e, setValues, values, providers)}
        >
          {providers &&
            providers.map(provider => (
              <option key={provider.id} value={provider.id}>
                {provider.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

const handleAdditionalInfo = (e, setValues, values, key) => {
  setValues({
    ...values,
    [key]: e.target.checked
  });
};

export const renderAdditionalInfo = props => {
  const { values, setValues } = props;
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label>Additional:</label>
      </div>
      <div className="form-group col-9">
        <div className="d-inline-block">
          <span className="custom-checkbox custom-control">
            <input
              id="downloadable"
              type="checkbox"
              value="downloadable"
              onChange={e => handleAdditionalInfo(e, setValues, values, 'downloadable')}
              checked={values.downloadable}
              className="custom-control-input"
            />
            <label htmlFor="downloadable" className="custom-control-label">
              Downloadable
            </label>
          </span>
        </div>
        <div className="d-inline-block ml-1">
          <span className="custom-checkbox custom-control">
            <input
              id="adultsOnly"
              type="checkbox"
              value="adultsOnly"
              onChange={e => handleAdditionalInfo(e, setValues, values, 'adultsOnly')}
              checked={values.adultsOnly}
              className="custom-control-input"
            />
            <label htmlFor="adultsOnly" className="custom-control-label">
              Adults
            </label>
          </span>
        </div>
        <div className="d-inline-block ml-1">
          <span className="custom-checkbox custom-control">
            <input
              id="promotedMovie"
              type="checkbox"
              value="promotedMovie"
              onChange={e => handleAdditionalInfo(e, setValues, values, 'promotedMovie')}
              checked={values.promotedMovie}
              className="custom-control-input"
            />
            <label htmlFor="promotedMovie" className="custom-control-label">
              Promo movie
            </label>
          </span>
        </div>
      </div>
    </div>
  );
};

export const renderIMDB = (props, fillDetails) => {
  const { values, errors, touched, handleChange } = props;
  const key = 'imdbId';
  return (
    <div className="form-row">
      <div className="form-group col-3">
        <label>IMDB ID</label>
      </div>
      <div className="form-group d-flex col-9">
        <input
          id={key}
          placeholder="IMDB ID"
          type="text"
          value={(values[key] && values[key].toString()) || ''}
          onChange={handleChange}
          className={
            errors[key] && touched[key]
              ? 'text-input form-control error'
              : 'text-input form-control'
          }
        />
        {errors[key] && touched[key] && <div className="input-feedback">{errors[key]}</div>}
        {fillDetails && (
          <div
            className="d-flex btn pt-0 pb-0 pl-1 pr-1 btn-primary text-nowrap ml-2 align-items-center cursor-pointer"
            onClick={fillDetails}
          >
            Fill details
          </div>
        )}
      </div>
    </div>
  );
};
