import { FETCH_MOVIES_TO_CHECK } from './actions';

const initialState = {
  moviesToCheck: {
    data: [],
    loading: false
  }
};

const moviesToCheckReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MOVIES_TO_CHECK.REQUEST:
      return { ...state, moviesToCheck: { data: [], loading: true } };

    case FETCH_MOVIES_TO_CHECK.SUCCESS:
      return { ...state, moviesToCheck: { data: payload, loading: false } };

    case FETCH_MOVIES_TO_CHECK.ERROR:
      return { ...state, moviesToCheck: initialState.moviesToCheck };

    default:
      return state;
  }
};

export default moviesToCheckReducer;
