import * as Yup from 'yup';

export const formSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('This field is required'),
    rating: Yup.number().typeError('This field must be a number'),
    duration: Yup.number().typeError('This field must be a number'),
    year: Yup.number()
      .typeError('This field must be a number')
      .min(1900, 'This field must be greater than 1900')
      .max(new Date().getFullYear(), 'this field must be less than 2020')
  });
};

export const IMAGE_TYPE = {
  screen: 'screen',
  cover: 'cover',
  promoCover: 'promo_cover'
};