import { FETCH_MOVIES, FETCH_MOVIE_PROVIDERS, SEND_MOVIES, SEND_MOVIES_RESET } from './actions';

const initialState = {
  movies: {
    data: null,
    loading: false
  },
  movieProviders: {
    data: null,
    loading: false
  },
  sendMovies: {
    loading: false,
    success: false,
    error: null
  }
};

const moviesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MOVIES.REQUEST:
      return { ...state, movies: { data: null, loading: true } };

    case FETCH_MOVIES.SUCCESS:
      return { ...state, movies: { data: payload, loading: false } };

    case FETCH_MOVIES.ERROR:
      return { ...state, movies: initialState.movies };

    case FETCH_MOVIE_PROVIDERS.REQUEST:
      return { ...state, movieProviders: { data: null, loading: true } };

    case FETCH_MOVIE_PROVIDERS.SUCCESS:
      return { ...state, movieProviders: { data: payload, loading: false } };

    case FETCH_MOVIE_PROVIDERS.ERROR:
      return { ...state, movieProviders: initialState.movieProviders };

    case SEND_MOVIES.REQUEST:
      return { ...state, sendMovies: { loading: true, success: false, error: null } };

    case SEND_MOVIES.SUCCESS:
      return { ...state, sendMovies: { loading: false, success: true, error: null } };

    case SEND_MOVIES.ERROR:
      return { ...state, sendMovies: { loading: false, success: false, error: payload } };

    case SEND_MOVIES_RESET:
      return { ...state, sendMovies: initialState.sendMovies };

    default:
      return state;
  }
};

export default moviesReducer;
