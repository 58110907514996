import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { loginUser } from '../actions';

const Login = props => (
  <div className="container h-100">
    <div className="row align-items-sm-center h-100">
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={values => {
          let errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          if (!values.password) {
            errors.password = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          props.loginUser(values, props.history)
            .then(() =>  setSubmitting(false))
            .catch(e => {
              setSubmitting(false);
              setFieldError('submit', e);
            });
        }}
      >
        {({ isSubmitting, errors }) => (
          <div className="card card-topline col-lg-6 mx-auto">
            <div className="card-body">
              <h2 className="text-center">Movie Queen</h2>
              <Form>
                <ul className="d-block invalid-feedback list-unstyled">
                  {errors.submit && errors.submit.map((msg, index) => <li key={index}>{msg}</li>)}
                </ul>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    autoComplete="username"
                  />
                  <ErrorMessage name="email" component="div" className="d-block invalid-feedback" />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    className="form-control"
                    autoComplete="current-password"
                  />
                  <ErrorMessage name="password" component="div" className="d-block invalid-feedback" />
                </div>
                <div className="form-group text-center">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Sign in
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  </div>
);

const mapDispatchToProps = {
  loginUser
};

export default connect(
  null,
  mapDispatchToProps
)(Login);
