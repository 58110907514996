import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Redirect } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import App from './App';
import Login from './app/auth/components/Login';
import rootReducer from './app/common/reducers';
import { requestMiddleware } from './requestMiddleware';
import { axiosConfig, isUserAuthorized } from './axios.config';
import './stylesheets/main.sass';
import history from './history';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk, requestMiddleware];
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

axiosConfig.init(store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/login" component={Login} />
      <Route
        path="/"
        render={() =>
          isUserAuthorized() ? <Route path="/" component={App} /> : <Redirect to="/login" />
        }
      />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
