import {
  GLOBAL_ERRORS,
  NOTIFICATION_DISPLAY_TIME,
  NOTIFICATION_TYPE
} from '../consts/NotificationsConsts';

export const SHOW_NOTIFICATION = '[Notifications] Show notification';
export const DELETE_NOTIFICATION = '[Notifications] Delete notification';

function showNotificationAction(notification) {
  return { type: SHOW_NOTIFICATION, payload: notification };
}

export function deleteNotification(notificationId) {
  return { type: DELETE_NOTIFICATION, payload: notificationId };
}

export function showNotification(notification) {
  return function(dispatch) {
    dispatch(showNotificationAction(notification));
    setTimeout(() => {
      dispatch(deleteNotification(notification.id));
    }, NOTIFICATION_DISPLAY_TIME);
  };
}

export function showNotificationError(error) {
  error = error.response || error;
  let errorCode = error.status;
  let errorMessage = GLOBAL_ERRORS[errorCode] || error.data.errors;
  let notification = {
    message: errorMessage,
    type: NOTIFICATION_TYPE.ERROR
  };
  return function(dispatch) {
    dispatch(showNotification(notification));
  };
}

export function showNotificationSuccess(message) {
  return function(dispatch) {
    dispatch(showNotification({ message: message, type: NOTIFICATION_TYPE.SUCCESS }));
  };
}
