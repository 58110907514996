import { combineReducers } from 'redux';
import moviesReducer from '../movies/reducer';
import singleMovieReducer from './../singleMovie/reducer';
import commonReducer from './commonReducer';
import notifications from './../notifications/reducers/NotificationsReducer';
import moviesToCheckReducer from './../checkMovies/reducer';

const rootReducer = combineReducers({
  moviesReducer,
  singleMovieReducer,
  commonReducer,
  notifications,
  moviesToCheckReducer
});

export default rootReducer;
