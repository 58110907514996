import React from 'react';

function Button(props) {
  return (
    React.createElement(
      props.component,
      {className: `btn ${props.className}`, disabled: props.loading},
      props.loading ? (
        <React.Fragment>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="ml-1">{props.loadingText ? props.loadingText : 'Loading...'}</span>
        </React.Fragment>
      ) : props.children
    )
  )
}

export default Button;