import React from 'react';
import { getIcon } from './../../common/components/icons';
import MediaModal from './MediaModal';
import { Throbber } from '../../common/components/Throbber';

class TableWithMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalData: null
    };
  }

  setModalData = item => {
    return this.props.video ?
      {
        title: item.fileName,
        url: item.url
      } :
      {
        title: this.props.title,
        url: item.urls
      };
  };

  toggleModal = item => {
    if (this.state.showModal) {
      this.setState({
        showModal: false,
        modalData: null
      });
    } else {
      this.setState({
        showModal: true,
        modalData: this.setModalData(item)
      });
    }
  };

  renderDeleteButton = item => {
    if (item.deleting) {
      return <div className="spinner-border spinner-border-sm text-danger ml-1" role="status" />
    } else {
      return (
        <span onClick={() => this.props.handleDelete(item.id)} className="delete-icon ml-1">
          &times;
        </span>
      )
    }
  };

  render() {
    return (
      <div className={'table-responsive-lg' + (this.props.loading ? ' overlay overlay--white' : '')}>
        {this.props.loading && <Throbber customClass="center large" />}
        <table className="table">
          <thead>
            <tr>
              <th scope="col">File name</th>
              <th scope="col">Type</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(item => (
              <tr key={item.id}>
                <td className="w-100">{item.fileName}</td>
                <td>{item.type || item.format}</td>
                <td>
                  {
                    item.deletedAt ? (
                      <span className="text-danger">Deleted</span>
                    ) : (
                      <div className="d-flex">
                        <span onClick={() => this.toggleModal(item)} className="icon-wrapper">
                          {getIcon('loupe')}
                        </span>
                        {this.props.handleDelete && this.renderDeleteButton(item)}
                      </div>
                    )
                  }
                </td>
              </tr>
            ))}
            {!this.props.data.length && (
              <tr>
                <td colSpan="3" className="text-center">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {this.state.showModal && (
          <MediaModal
            toggleModal={this.toggleModal}
            data={this.state.modalData}
            video={this.props.video}
          />
        )}
      </div>
    );
  }
}

export default TableWithMedia;
