import React from 'react';
import Modal from '../../common/components/Modal';
import Player from '../../common/components/Player';

const MediaModal = props => {
  const renderImages = () => {
    return (
      props.data.url &&
      props.data.url.map((item, index) => {
        return (
          <div
            key={index + item.version}
            className="image-wrapper text-center d-inline-block align-items-center p-2"
          >
            <img src={item.url} alt={item.version} className="img-fluid" />
            <p className="py-5">{item.version}</p>
          </div>
        );
      })
    );
  };

  const renderVideo = () => (
    <Player url={props.data.url} />
  );

  return (
    <Modal onHide={props.toggleModal} size={props.video ? 'modal-auto' : 'modal-100 modal-height-100'}>
      <div className="modal-header">
        <h5 className="modal-title">{props.data.title}</h5>
      </div>
      <div className="modal-body text-center">
        {props.video ? renderVideo() : renderImages()}
      </div>
    </Modal>
  );
};

export default MediaModal;
