import React, { PureComponent } from 'react';

const bootstrapClass = 'modal-open';

class Modal extends PureComponent {
  componentDidMount() {
    document.body.classList.add(bootstrapClass);
  }

  componentWillUnmount() {
    document.body.classList.remove(bootstrapClass);
  }

  render() {
    return (
      <React.Fragment>
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className={'modal-dialog modal-dialog-centered ' + (this.props.size ? this.props.size : '')} role="document">
            <div className="modal-content">
              <span className="modal-close" onClick={this.props.onHide}>
                &times;
              </span>
              {this.props.children}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </React.Fragment>
    )
  }
}

export default Modal;
