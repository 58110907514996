import axios from 'axios';
import { createActionTypes } from '../../requestMiddleware';

export const FETCH_LANGUAGES = createActionTypes('[Common] Fetch languages');
export function fetchLanguages() {
  return {
    types: FETCH_LANGUAGES,
    request: () => axios.get('/languages')
  };
}

export const FETCH_GENRES = createActionTypes('[Common] Fetch genres');
export function fetchGenres() {
  return {
    types: FETCH_GENRES,
    request: () => axios.get('/genres')
  };
}
