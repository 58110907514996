import React, { PureComponent } from 'react';
import TablePagination from '../../common/components/TablePagination';
import { changeTimestampToDate } from '../../common/helpers';
import { Link } from 'react-router-dom';

class MoviesTable extends PureComponent {
  handleSort = columnName => {
    this.props.onSortChange(columnName);
  };

  renderSortArrow = columnID => {
    const sortColumn = this.props.sort.column;
    const className = `caret caret-${this.props.sort.order === 'asc' ? 'up' : 'down'}`;
    return <span className={sortColumn === columnID ? className : null} />;
  };

  render() {
    return (
      <div className="table-responsive-lg">
        <table className="table">
          <thead>
            <tr>
              <th>
                <span className="custom-checkbox custom-control">
                  <input
                    id="check-all"
                    type="checkbox"
                    onChange={e => this.props.onMovieCheck('currentPage', e.target.checked)}
                    checked={this.props.allMoviesChecked}
                    className="custom-control-input"
                  />
                  <label htmlFor="check-all" className="custom-control-label">
                    &nbsp;
                  </label>
                </span>
              </th>
              <th scope="col" onClick={() => this.handleSort('id')}>
                Id {this.renderSortArrow('id')}
              </th>
              <th scope="col" onClick={() => this.handleSort('title')}>
                Title {this.renderSortArrow('title')}
              </th>
              <th scope="col" onClick={() => this.handleSort('createdAt')}>
                Created {this.renderSortArrow('createdAt')}
              </th>
              <th scope="col" onClick={() => this.handleSort('movieProvider.id')}>
                Provider {this.renderSortArrow('movieProvider.id')}
              </th>
              <th scope="col" onClick={() => this.handleSort('status')}>
                Status {this.renderSortArrow('status')}
              </th>
              <th scope="col" onClick={() => this.handleSort('licenseExpireAt')}>
                License date {this.renderSortArrow('licenseExpireAt')}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.movies.map(movie => (
              <tr key={movie.id}>
                <td>
                  <span className="custom-checkbox custom-control">
                    <input
                      id={`${movie.id}-movie`}
                      type="checkbox"
                      onChange={e => this.props.onMovieCheck(movie.id, e.target.checked)}
                      checked={this.props.checkedMovies.indexOf(movie.id) > -1}
                      className="custom-control-input"
                    />
                    <label htmlFor={`${movie.id}-movie`} className="custom-control-label">
                      &nbsp;
                    </label>
                  </span>
                </td>
                <td>{movie.id}</td>
                <td><Link to={`/movies/${movie.id}`}>{movie.title}</Link></td>
                <td>{changeTimestampToDate(movie.createdAt)}</td>
                <td>{movie.movieProvider && movie.movieProvider.name}</td>
                <td>{movie.status}</td>
                <td>{changeTimestampToDate(movie.licenseExpireAt)}</td>
              </tr>
            ))}
            {!this.props.movies.length && (
              <tr>
                <td colSpan="7" className="text-center">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <TablePagination
          currentPage={this.props.currentPage}
          totalPages={this.props.totalPages}
          onPageChange={this.props.onPageChange}
        />
      </div>
    );
  }
}

export default MoviesTable;
