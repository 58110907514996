import axios from 'axios';
import { createActionTypes } from '../../requestMiddleware';

const SINGLE_MOVIE_UPDATE_MSG = 'Movie has been updated.';
const SINGLE_MOVIE_IMDB_UPDATE_MSG = 'Movie form has been filled.';
const IMAGE_CREATE_MSG = 'Image has been added.';
const IMAGE_DELETE_MSG = 'Image has been deleted.';

export const FETCH_SINGLE_MOVIE = createActionTypes('[Single movie] Fetch single movie');
export function fetchSingleMovie(id) {
  return {
    types: FETCH_SINGLE_MOVIE,
    request: () => axios.get(`/movies/${id}`)
  };
}

export const IMDB_UPDATE_MOVIE = createActionTypes('[Single movie] IMDB update');
export function imdbUpdateMovie(id) {
  return {
    types: IMDB_UPDATE_MOVIE,
    request: () => axios.put(`/movies/${id}/imdb`),
    notifySuccess: SINGLE_MOVIE_IMDB_UPDATE_MSG
  };
}

export const CLEAR_SINGE_MOVIE = '[Single movie] Clear single movie';
export const clearSingleMovie = () => {
  return { type: CLEAR_SINGE_MOVIE };
};

export const UPDATE_SINGLE_MOVIE = createActionTypes('[Single movie] Update single movie');
export function updateSingleMovie(id, params) {
  return {
    types: UPDATE_SINGLE_MOVIE,
    request: () => axios.put(`/movies/${id}`, params),
    notifySuccess: SINGLE_MOVIE_UPDATE_MSG
  };
}

export const CREATE_IMAGE = createActionTypes('[Single movie] Create image');
export function createImage(id, params) {
  return {
    types: CREATE_IMAGE,
    request: () => axios.post(`/movies/${id}/images`, params),
    responseTimeout: 1000,
    notifySuccess: IMAGE_CREATE_MSG
  };
}

export const DELETE_IMAGE = createActionTypes('[Single movie] Delete image');
export function deleteImage(id, imageType) {
  return {
    types: DELETE_IMAGE,
    requestParams: {id, imageType},
    request: () => axios.delete(`/images/${id}`),
    responseTimeout: 1000,
    notifySuccess: IMAGE_DELETE_MSG
  };
}