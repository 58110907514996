import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import MovieDetails from './MovieDetails';
import MovieGenres from './MovieGenres';
import TableWithMedia from './TableWithMedia';
import { formSchema, IMAGE_TYPE } from './../consts';
import UploadFileButton from '../../common/components/UploadFileButton';
import Button from '../../common/components/Button';

class SingleMovieSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.uploadingImageType = null;
  }

  handleSubmit = data => {
    const params = {
      title: data.title,
      description: data.description,
      year: data.year,
      director: data.director,
      cast: data.cast,
      country: data.country,
      rating: data.rating,
      duration: data.duration,
      movieProviderId: (data.movieProvider && data.movieProvider.id) || null,
      imdbId: data.imdbId,
      downloadable: data.downloadable,
      licenseExpireAt: data.licenseExpireAt,
      adultsOnly: data.adultsOnly,
      releaseDate: data.releaseDate,
      languageId: (data.language && data.language.id) || null,
      genreIds: (data.genres && data.genres.map(item => item.id)) || [],
      promoted: data.promotedMovie
    };
    this.props.updateSingleMovie(params);
  };

  addImage = (imgType, file) => {
    if (file) {
      const formData = new FormData();
      formData.append('type', imgType);
      formData.append('image', file);
      this.uploadingImageType = imgType;
      this.props.addImage(this.props.movie.id, formData);
    }
  };

  isUploadingImgType(type) {
    return this.uploadingImageType === type;
  }

  render() {
    let isCoverImgUploading, isScreenImgUploading, isPromoCoverImgUploading;
    if (this.props.uploadingImage) {
      isCoverImgUploading = this.isUploadingImgType(IMAGE_TYPE.cover);
      isScreenImgUploading = this.isUploadingImgType(IMAGE_TYPE.screen);
      isPromoCoverImgUploading = this.isUploadingImgType(IMAGE_TYPE.promoCover);
    }
    return (
      <Formik
        initialValues={this.props.movie}
        onSubmit={this.handleSubmit}
        validationSchema={formSchema}
      >
        {props => {
          return (
            <React.Fragment>
              <div className="card card-topline col-12 mb-3">
                <div className="card-body">
                  <h2 className="d-inline-block mb-2">Movie details:</h2>
                  <MovieDetails
                    {...props}
                    movieProviders={this.props.movieProviders}
                    languages={this.props.languages}
                    movie={this.props.movie}
                    imdbUpdateMovie={this.props.imdbUpdateMovie}
                  />
                </div>
              </div>
              <div className="card card-topline col-12 mb-3">
                <div className="card-body">
                  <h2 className="d-inline-block mb-2">Movie genres:</h2>
                  <MovieGenres {...props} genres={this.props.genres} />
                </div>
              </div>
              <div className="card card-topline col-12 mb-3">
                <div className="card-body">
                  <h2 className="d-inline-block mb-2">Covers:</h2>
                  <UploadFileButton
                    id="upload-covers"
                    customClass="float-right"
                    upload={files => this.addImage(IMAGE_TYPE.cover, files)}
                  >
                    <Button component="div" className="btn-primary cursor-pointer" loadingText="Uploading"
                      loading={isCoverImgUploading}
                    >
                      Add cover
                    </Button>
                  </UploadFileButton>
                  <TableWithMedia
                    title="Covers:"
                    data={(this.props.movie && this.props.movie.covers) || [] }
                    handleDelete={id => this.props.deleteImage(id, IMAGE_TYPE.cover)}
                    loading={isCoverImgUploading}
                  />
                </div>
              </div>
              <div className="card card-topline col-12 mb-3">
                <div className="card-body">
                  <h2 className="d-inline-block mb-2">Screens:</h2>
                  <UploadFileButton
                    id="upload-screen"
                    customClass="float-right"
                    upload={files => this.addImage(IMAGE_TYPE.screen, files)}
                  >
                    <Button component="div" className="btn-primary cursor-pointer" loadingText="Uploading"
                      loading={isScreenImgUploading}
                    >
                      Add screen
                    </Button>
                  </UploadFileButton>
                  <TableWithMedia
                    title="Screens:"
                    data={(this.props.movie && this.props.movie.screens) || [] }
                    handleDelete={id => this.props.deleteImage(id, IMAGE_TYPE.screen)}
                    loading={isScreenImgUploading}
                  />
                </div>
              </div>
              <div className="card card-topline col-12 mb-3">
                <div className="card-body">
                  <h2 className="d-inline-block mb-2">Promo covers:</h2>
                  <UploadFileButton
                    id="upload-promo"
                    customClass="float-right"
                    upload={files => this.addImage(IMAGE_TYPE.promoCover, files)}
                  >
                    <Button component="div" className="btn-primary cursor-pointer" loadingText="Uploading"
                      loading={isPromoCoverImgUploading}
                    >
                      Add promo cover
                    </Button>
                  </UploadFileButton>
                  <TableWithMedia
                    title="Promo covers:"
                    data={(this.props.movie && this.props.movie.promoCovers) || [] }
                    handleDelete={id => this.props.deleteImage(id, IMAGE_TYPE.promoCover)}
                    loading={isPromoCoverImgUploading}
                  />
                </div>
              </div>
              <div className="card card-topline col-12 mb-3">
                <div className="card-body">
                  <h2 className="d-inline-block mb-2">Full movies:</h2>
                  <TableWithMedia
                    data={(props.values && props.values.fullMovies) || [] }
                    video={true}
                  />
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Formik>
    );
  }
}

export default SingleMovieSettings;
