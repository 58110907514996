import axios from 'axios';
import { createActionTypes } from '../../requestMiddleware';

export const FETCH_MOVIES_TO_CHECK = createActionTypes('[Check movies] Fetch movies to check');
export function fetchMoviesToCheck() {
  return {
    types: FETCH_MOVIES_TO_CHECK,
    request: () => axios.get('/video_files')
  };
}
