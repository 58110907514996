export const WAIT_FOR_PLAYING_TIME = 2000;
export const IN_PROGRESS = 'In progress';
export const DONE = 'Done';
export const NOT_STARTED = 'Not started';
export const LOADING = 'Loading';

export const CSVHeaders = [
  {
    label: 'Id',
    key: 'id'
  },
  {
    label: 'File name',
    key: 'fileName'
  },
  {
    label: 'Format',
    key: 'format'
  },
  {
    label: 'Resolution',
    key: 'resolution'
  },
  {
    label: 'URL',
    key: 'url'
  },
  {
    label: 'Error',
    key: 'error'
  }
];
