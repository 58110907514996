import React, { PureComponent } from 'react';
import MoviesTable from './MoviesTable';
import { sortAsc, sortDesc } from '../../common/helpers';
import { ITEMS_PER_PAGE } from '../const';
import MoviesFilters from './MoviesFilters';
import MoviesModal from './MoviesModal';

class MoviesListContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      sort: { column: 'id', order: 'asc' },
      movies: this.props.movies,
      checkedMovies: []
    };
  }

  onPaginationPageChange = currentPage => {
    this.setState({ currentPage });
  };

  onSortChange = columnName => {
    let column = this.state.sort.column;
    let order = this.state.sort.order;
    if (columnName === column) {
      order = order === 'asc' ? 'desc' : 'asc';
    } else {
      column = columnName;
    }
    this.setState({ sort: { column, order } });
  };

  sortMovies = movies => {
    const key = this.state.sort.column.split('.');
    const keyLength = key.length;
    return movies.sort((a, b) => {
      let i = 0;
      while (i < keyLength) {
        a = a[key[i]];
        b = b[key[i]];
        i++;
      }
      return this.state.sort.order === 'asc' ? sortAsc(a, b) : sortDesc(a, b);
    });
  };

  getMoviesForCurrentPage = movies => {
    const offset = (this.state.currentPage - 1) * ITEMS_PER_PAGE;
    return movies.slice(offset, offset + ITEMS_PER_PAGE);
  };

  getTotalPagesNumber = movies => {
    return Math.ceil(movies.length / ITEMS_PER_PAGE);
  };

  updateMovies = movies => {
    this.setState({ movies, currentPage: 1, checkedMovies: [] });
  };

  onMovieCheck = (movieID, checked) => {
    if (movieID === 'all') {
      this.setState({
        checkedMovies: checked ? this.state.movies.map(movie => movie.id) : []
      });
    } else if (movieID === 'currentPage') {
      const sortedMovies = this.sortMovies(this.state.movies);
      const currentMovies = this.getMoviesForCurrentPage(sortedMovies);
      let checkedMovies = [...this.state.checkedMovies];
      if (checked) {
        currentMovies.forEach(item => {
          if (!checkedMovies.includes(item.id)) {
            checkedMovies.push(item.id);
          }
        });
      } else {
        checkedMovies = checkedMovies.filter(id => !currentMovies.find(movie => movie.id === id));
      }
      this.setState({ checkedMovies });
    } else {
      let checkedMovies = [...this.state.checkedMovies];
      if (checked) {
        checkedMovies.push(movieID);
      } else {
        checkedMovies = checkedMovies.filter(id => id !== movieID);
      }
      this.setState({ checkedMovies });
    }
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal }, () => {
      if (!this.state.showModal) {
        this.props.resetMoviesStatus();
      }
    });
  };

  sendMoviesEvent = () => {
    this.props.sendMoviesEvent(this.state.checkedMovies);
    this.setState({ checkedMovies: [] });
  };

  render() {
    const sortedMovies = this.sortMovies(this.state.movies);
    const currentMovies = this.getMoviesForCurrentPage(sortedMovies);
    const totalPages = this.getTotalPagesNumber(this.state.movies);
    const checkedMoviesLength = this.state.checkedMovies.length;
    const currentMoviesChecked = !currentMovies.find(
      item => !this.state.checkedMovies.includes(item.id)
    );
    const allMoviesChecked =
      this.state.movies.length === checkedMoviesLength && this.state.movies.length > 0;

    return (
      <React.Fragment>
        <div className="card card-topline mb-3 w-100">
          <MoviesFilters
            movies={this.props.movies}
            movieProviders={this.props.movieProviders}
            updateMovies={this.updateMovies}
          />
        </div>
        <div className="card card-topline w-100">
          <div className="card-body">
            <h2 className="d-inline-block mb-2">Movies</h2>
            <button
              className="btn btn-primary float-right"
              disabled={this.props.sendMoviesStatus.loading || !checkedMoviesLength}
              onClick={this.toggleModal}
            >
              {this.props.sendMoviesStatus.loading ? (
                'Sending...'
              ) : (
                <span>Send movies ({checkedMoviesLength})</span>
              )}
            </button>
            <button
              className="d-inline-block mr-1 btn btn-primary float-right"
              onClick={() => this.onMovieCheck('all', !allMoviesChecked)}
            >
              {allMoviesChecked ? 'Uncheck all' : 'Check all'}
            </button>
            {this.props.movies && (
              <MoviesTable
                movies={currentMovies}
                checkedMovies={this.state.checkedMovies}
                allMoviesChecked={currentMoviesChecked}
                currentPage={this.state.currentPage}
                totalPages={totalPages}
                sort={this.state.sort}
                onMovieCheck={this.onMovieCheck}
                onPageChange={this.onPaginationPageChange}
                onSortChange={this.onSortChange}
              />
            )}
          </div>
        </div>
        {this.state.showModal && (
          <MoviesModal
            actionStatus={this.props.sendMoviesStatus}
            moviesLength={checkedMoviesLength}
            sendMoviesEvent={this.sendMoviesEvent}
            toggleModal={this.toggleModal}
          />
        )}
      </React.Fragment>
    );
  }
}
export default MoviesListContainer;
