import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchMovieProviders, fetchMovies, sendMovies, sendMoviesReset } from '../actions';
import MoviesListContainer from './MoviesListContainer';
import { Throbber } from '../../common/components/Throbber';

class MoviesListWrapper extends PureComponent {
  componentDidMount() {
    if (!this.props.movies) {
      this.props.dispatch(fetchMovies());
    }
    if (!this.props.movieProviders) {
      this.props.dispatch(fetchMovieProviders());
    }
  }

  sendMovies = params => {
    this.props.dispatch(sendMovies(params));
  };

  resetMoviesStatus = () => {
    this.props.dispatch(sendMoviesReset());
  };

  render() {
    return (
      <div className="container mb-3">
        <div className="row">
          {this.props.movies && this.props.movieProviders ? (
            <MoviesListContainer
              movies={this.props.movies}
              movieProviders={this.props.movieProviders}
              sendMoviesEvent={this.sendMovies}
              sendMoviesStatus={this.props.sendMoviesStatus}
              resetMoviesStatus={this.resetMoviesStatus}
            />
          ) : (
            <Throbber customClass="center large" />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ moviesReducer }) {
  return {
    movies: moviesReducer.movies.data,
    loadingMovies: moviesReducer.movies.loading,
    movieProviders: moviesReducer.movieProviders.data,
    loadingMovieProviders: moviesReducer.movieProviders.loading,
    sendMoviesStatus: moviesReducer.sendMovies
  };
}

export default connect(mapStateToProps)(MoviesListWrapper);
