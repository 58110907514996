export function sortAsc(a, b) {
  return (a > b) - (a < b);
}

export function sortDesc(a, b) {
  return (b > a) - (b < a);
}

export function changeTimestampToDate(timestamp) {
  if (timestamp) {
    const d = new Date(timestamp * 1000);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
}

export function debounce(fn) {
  let timerId;
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(() => {
    fn();
    timerId = null;
  }, 1000);
}
