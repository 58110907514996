import React, { Component } from 'react'
import shaka from 'shaka-player'

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {errors: null}
  }

  componentDidMount() {
    // Install built-in polyfills to patch browser incompatibilities.
    shaka.polyfill.installAll();

    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      this.initPlayer();
    } else {
      // This browser does not have the minimum set of APIs we need.
      this.setState({errors: {error: 'Browser not supported!'}});
      console.error('Browser not supported!');
    }
  }

  initPlayer(){
    const player = new shaka.Player(this.refs.video);

    // Listen for error events.
    player.addEventListener('error', this.onErrorEvent);

    // Try to load a manifest.
    // This is an asynchronous process.
    player.load(this.props.url).then(function() {
      // This runs if the asynchronous load is successful.
      console.log('The video has now been loaded!');
    }).catch(this.onError);  // onError is executed if the asynchronous load fails.
  }

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError = error => {
    // Log the error.
    this.setState({errors: error});
    console.error('Error code', error.code, 'object', error);
  };

  renderErrors = () => (
    <div className="text-left">
      <p className="text-danger">Error! Error details:</p>
      <ul>
        {Object.keys(this.state.errors).map(key => {
          if (
            typeof this.state.errors[key] === 'number' ||
            typeof this.state.errors[key] === 'string'
          ) {
            return (
              <li key={key}>
                {key}: { this.state.errors[key] }
              </li>
            )
          }
          return null;
        })}
      </ul>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.state.errors && this.renderErrors()}
        <video width="840" ref="video" controls autoPlay />
      </React.Fragment>
    );
  }
}

export default Player;