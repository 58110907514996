import React, { PureComponent } from 'react';
import Modal from '../../common/components/Modal';

class MoviesModal extends PureComponent {
  renderErrorMessage = error => <span className="text-danger">{error}</span>;

  renderSuccessMessage = () => <span>Movies have been sent successfully</span>;

  renderContent = () => (
    <span>
      {this.props.moviesLength} {this.props.moviesLength === 1 ? 'title ' : 'titles '}
      will be send to products
    </span>
  );

  render() {
    const { loading, error, success } = this.props.actionStatus;
    return (
      <Modal onHide={this.props.toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Send movies</h5>
        </div>
        <div className="modal-body">
          {
            loading ? 'Sending...' : error ?
              this.renderErrorMessage(error) : success ?
                this.renderSuccessMessage() : this.renderContent()
          }
        </div>
        <div className="modal-footer text-center">
          <button
            type="button"
            className="btn btn-secondary"
            disabled={loading}
            onClick={this.props.toggleModal}
          >
            {success ? 'Ok' : 'Cancel'}
          </button>
          {
            !success && (
              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={this.props.sendMoviesEvent}
              >
                Send
              </button>
            )
          }
        </div>
      </Modal>
    );
  }
}

export default MoviesModal;
