import React from 'react';

// default class styles => small, large, center, center-x, white
export const Throbber = ({ customClass }) => (
  <ul className={'throbber ' + (customClass ? customClass : '')}>
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
    <li />
  </ul>
);
