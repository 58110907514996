import axios from 'axios';
import { API_AUTH_CLIENT_KEY, API_AUTH_TOKEN_KEY, API_AUTH_UID_KEY } from '../common/consts';
import { createActionTypes } from '../../requestMiddleware';
import { setAuthenticationKeys } from '../../axios.config';
import history from './../../history';

export const LOGIN = createActionTypes('[Auth] Login user');
export function loginUser(data) {
  return {
    types: LOGIN,
    request: () => {
      const instance = axios.create();
      instance.interceptors.response.use(response => {
        return response.headers;
      });
      return instance.post('/auth/sign_in', data);
    },
    successCallback: responseHeader => {
      setAuthenticationKeys(
        responseHeader[API_AUTH_TOKEN_KEY],
        responseHeader[API_AUTH_CLIENT_KEY],
        responseHeader[API_AUTH_UID_KEY]
      );
      history.push('/');
    },
    errorCallback: error => {
      return Promise.reject(error.response.data && error.response.data.errors);
    }
  };
}

export const LOGOUT = createActionTypes('[Auth] Logout user');
export function logoutUser() {
  return {
    types: LOGOUT,
    request: () => {
      return axios.delete('/auth/sign_out');
    },
    successCallback: response => {
      localStorage.clear();
      history.push('/login');
    },
    errorCallback: () => {
      localStorage.clear();
      history.push('/login');
    }
  };
}
