import { FETCH_LANGUAGES, FETCH_GENRES } from './actions';

const initialState = {
  languages: {
    data: null,
    loading: false
  },
  genres: {
    data: null,
    loading: false
  }
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LANGUAGES.REQUEST:
      return { ...state, languages: { data: null, loading: true } };

    case FETCH_LANGUAGES.SUCCESS:
      return { ...state, languages: { data: payload, loading: false } };

    case FETCH_LANGUAGES.ERROR:
      return { ...state, languages: initialState.languages };

    case FETCH_GENRES.REQUEST:
      return { ...state, genres: { data: null, loading: true } };

    case FETCH_GENRES.SUCCESS:
      return { ...state, genres: { data: payload, loading: false } };

    case FETCH_GENRES.ERROR:
      return { ...state, genres: initialState.genres };

    default:
      return state;
  }
};

export default commonReducer;
