import React from 'react';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { logoutUser } from './app/auth/actions';
import { connect } from 'react-redux';
import MoviesListWrapper from './app/movies/components/MoviesListWrapper';
import SingleMovieContainer from './app/singleMovie/components/SingleMovieContainer';
import { getIcon } from './app/common/components/icons';
import Notifications from './app/notifications/components/Notifications';
import CheckMovies from './app/checkMovies/components/CheckMovies';

const App = ({ logoutUser }) => (
  <main>
    <nav className="navbar navbar-expand-md navbar-dark bg-secondary mb-5">
      <Link className="navbar-brand text-center" to="/">
        <span className="logo">{getIcon('logo')}</span>
        <span className="d-block">Movie Queen</span>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse">
        <div className="navbar-nav mr-auto">
          <NavLink className="nav-item nav-link" to="/movies" activeClassName="active">
            Movies
          </NavLink>
          <NavLink className="nav-item nav-link" to="/check-movies" activeClassName="active">
            Check movies
          </NavLink>
        </div>
        <span className="nav-item cursor-pointer text-white" onClick={() => logoutUser()}>
          Logout
        </span>
      </div>
    </nav>
    <Switch>
      <Route exact path="/movies" component={MoviesListWrapper} />
      <Route path="/movies/:id" component={SingleMovieContainer} />
      <Route path="/check-movies" component={CheckMovies} />
      <Redirect from="/" to="/movies" />
    </Switch>
    <Notifications />
  </main>
);

const mapDispatchToProps = {
  logoutUser
};

export default connect(
  null,
  mapDispatchToProps
)(App);
