export const ITEMS_PER_PAGE = 20;
export const STATUS_OPTIONS = [
  { id: 'uncompleted', name: 'Uncompleted' },
  { id: 'to_send', name: 'To send' },
  { id: 'sent', name: 'Sent' }
];
export const LICENCE_OPTIONS = [
  { id: 'active', name: 'License valid' },
  { id: 'expired', name: 'License expired' },
  { id: 'expiring', name: 'License expiring' }
];
export const ADULTS_OPTIONS = [{ id: 1, name: 'Only adults' }, { id: 0, name: 'Exclude adults' }];

export const filters = {
  PROVIDER_FILTER: 'movieProvider',
  STATUS_FILTER: 'status',
  LICENCE_FILTER: 'licenseExpireAt',
  ADULTS_FILTER: 'adultsOnly',
  ID_FILTER: 'id'
};

export const TODAY = +new Date() / 1000; // current date in timestamp (seconds)
export const DAYS_TO_EXPIRE = 7 * 86400; // days * seconds
