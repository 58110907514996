import {
  showNotificationSuccess,
  showNotificationError
} from './app/notifications/actions/NotificationsActions';

export function requestMiddleware({ dispatch, getState }) {
  return next => action => {
    const {
      types,
      request,
      shouldRequest = () => true,
      requestParams = {},
      successCallback,
      errorCallback,
      notifySuccess = false,
      notifyError = true,
      onSuccess = false,
      responseTimeout
    } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (typeof request !== 'function') {
      throw new Error('Expected request to be a function.');
    }

    if (!shouldRequest(getState())) {
      return;
    }

    const { REQUEST, SUCCESS, ERROR } = types;

    dispatch({ type: REQUEST, payload: requestParams });

    function successResponse(response) {
      dispatch({ type: SUCCESS, payload: response, requestParams });
      if (successCallback && typeof successCallback === 'function') {
        successCallback(response);
      }
      if (notifySuccess) {
        dispatch(showNotificationSuccess(notifySuccess));
      }
      if (onSuccess) {
        onSuccess(dispatch);
      }
    }

    let startRequest = responseTimeout ? new Date() : null;

    return request()
      .then(response => {
        if (responseTimeout && (new Date() - startRequest) < responseTimeout) {
          setTimeout(() => {
            successResponse(response);
          }, responseTimeout - (new Date() - startRequest));
        } else {
          successResponse(response);
        }
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: ERROR, payload: error.data || error.statusText });
        if (notifyError) {
          if (typeof notifyError === 'function') {
            if (notifyError(error)) {
              dispatch(showNotificationError(error));
            }
          } else {
            dispatch(showNotificationError(error));
          }
        }
        if (errorCallback && typeof errorCallback === 'function') {
          return errorCallback(error);
        }
      });
  };
}

export function createActionTypes(type) {
  return {
    REQUEST: type,
    SUCCESS: type + ' SUCCESS',
    ERROR: type + ' ERROR'
  };
}
