import React from 'react';

const MovieGenres = props => {
  const { setValues, values } = props;
  const handleGenre = e => {
    const target = e.target;
    if (target.checked) {
      const addedValue = props.genres.data.find(item => item.id.toString() === target.value);
      setValues({
        ...values,
        genres: [...values.genres, addedValue]
      });
    } else {
      setValues({
        ...values,
        genres: values.genres.filter(item => item.id.toString() !== target.value)
      });
    }
  };

  const genreChecked = item => {
    return !!(values.genres && values.genres.find(genre => genre.id === item));
  };

  return (
    <div className="genres-wrapper">
      {props.genres &&
        props.genres.data &&
        props.genres.data.map(item => {
          return (
            <div className="form-group" key={item.id}>
              <span className="custom-checkbox custom-control">
                <input
                  id={'genre' + item.id}
                  type="checkbox"
                  value={item.id}
                  onChange={handleGenre}
                  checked={genreChecked(item.id)}
                  className="custom-control-input"
                />
                <label htmlFor={'genre' + item.id} className="custom-control-label">
                  {item.name}
                </label>
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default MovieGenres;
