import React, { PureComponent } from 'react';
import {
  ADULTS_OPTIONS,
  DAYS_TO_EXPIRE,
  filters,
  LICENCE_OPTIONS,
  STATUS_OPTIONS,
  TODAY
} from '../const';
import { debounce } from '../../common/helpers';

class MoviesFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: {},
      searchQuery: ''
    };
  }

  selectMovies = (filters, searchQuery) => {
    const hasActiveFilters = Object.keys(filters).length > 0;
    if (hasActiveFilters || searchQuery) {
      return this.props.movies.filter(movie => {
        if (hasActiveFilters && searchQuery) {
          return filterMovie(movie, filters) && this.searchMovie(movie.title, searchQuery);
        } else if (hasActiveFilters) {
          return filterMovie(movie, filters);
        } else if (searchQuery) {
          return this.searchMovie(movie.title, searchQuery);
        } else {
          return true;
        }
      });
    } else {
      return this.props.movies;
    }
  };

  searchMovie = (movieTitle, searchQuery) => {
    return movieTitle && movieTitle.toLowerCase().includes(searchQuery.toLowerCase());
  };

  onSearchInputChange = e => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });
    debounce(() => {
      const movies = this.selectMovies(this.state.activeFilters, searchQuery);
      this.props.updateMovies(movies);
    });
  };

  onIDInputChange = e => {
    const inputName = e.target.name;
    let value = e.target.value;
    if (value && value.length) {
      value = parseInt(value, 10);
      if (isNaN(value)) return;
    }
    const activeFilters = {
      ...this.state.activeFilters,
      [filters.ID_FILTER]: { ...this.state.activeFilters[filters.ID_FILTER], [inputName]: value }
    };
    if (!value) {
      delete activeFilters[filters.ID_FILTER][inputName];
      if (!Object.keys(activeFilters[filters.ID_FILTER]).length) {
        delete activeFilters[filters.ID_FILTER];
      }
    }
    this.setState(prevState => ({ activeFilters }));
    debounce(() => {
      const movies = this.selectMovies(activeFilters, this.state.searchQuery);
      this.props.updateMovies(movies);
    });
  };

  updateFilter = (filterName, val) => {
    let activeFilters = { ...this.state.activeFilters, [filterName]: val };
    if (!val.length) {
      delete activeFilters[filterName];
    }
    const movies = this.selectMovies(activeFilters, this.state.searchQuery);
    this.setState({ activeFilters });
    this.props.updateMovies(movies);
  };

  renderFilterSelect(filterName, options) {
    return (
      <select
        className="form-control"
        id={filterName}
        value={this.state.activeFilters[filterName] || ''}
        onChange={e => this.updateFilter(filterName, e.target.value)}
      >
        <option value="">Any</option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    );
  }

  renderIDRange = () => {
    const from =
      this.state.activeFilters[filters.ID_FILTER] &&
      this.state.activeFilters[filters.ID_FILTER].from;
    const to =
      this.state.activeFilters[filters.ID_FILTER] && this.state.activeFilters[filters.ID_FILTER].to;
    return (
      <div className="form-row">
        <div className="col">
          <input
            name="from"
            className="form-control"
            placeholder="from"
            value={from ? from : ''}
            onChange={this.onIDInputChange}
          />
        </div>
        <div className="col">
          <input
            name="to"
            className="form-control"
            placeholder="to"
            value={to ? to : ''}
            onChange={this.onIDInputChange}
          />
        </div>
      </div>
    );
  };

  resetFilters = () => {
    this.setState({ activeFilters: {}, searchQuery: '' });
    this.props.updateMovies(this.props.movies);
  };

  render() {
    return (
      <div className="card-body">
        <div className="text-right">
          <button className="btn btn-primary" onClick={this.resetFilters}>
            Reset all
          </button>
        </div>
        <div className="form-row mb-2">
          <div className="col-3">
            <label htmlFor={filters.PROVIDER_FILTER}>Provider</label>
            {this.renderFilterSelect(filters.PROVIDER_FILTER, this.props.movieProviders)}
          </div>
          <div className="col-3">
            <label htmlFor={filters.STATUS_FILTER}>Status</label>
            {this.renderFilterSelect(filters.STATUS_FILTER, STATUS_OPTIONS)}
          </div>
          <div className="col-3">
            <label htmlFor={filters.LICENCE_FILTER}>License</label>
            {this.renderFilterSelect(filters.LICENCE_FILTER, LICENCE_OPTIONS)}
          </div>
          <div className="col-3">
            <label htmlFor={filters.ADULTS_FILTER}>Adults</label>
            {this.renderFilterSelect(filters.ADULTS_FILTER, ADULTS_OPTIONS)}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-6">
            <label htmlFor="search">Search by title:</label>
            <input
              id="search"
              placeholder="Find title"
              className="form-control"
              value={this.state.searchQuery}
              onChange={this.onSearchInputChange}
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="from">Select ID:</label>
            {this.renderIDRange()}
          </div>
        </div>
      </div>
    );
  }
}

export default MoviesFilters;

function filterMovie(movie, activeFilters) {
  const match = [];
  if (activeFilters[filters.STATUS_FILTER]) {
    match.push(movie[filters.STATUS_FILTER] === activeFilters[filters.STATUS_FILTER]);
  }
  if (activeFilters[filters.LICENCE_FILTER]) {
    const licenceExpireDate = movie[filters.LICENCE_FILTER];
    match.push(filterMoviesByLicence(licenceExpireDate, activeFilters[filters.LICENCE_FILTER]));
  }
  if (activeFilters[filters.ADULTS_FILTER]) {
    const adultsOnly = movie[filters.ADULTS_FILTER] ? '1' : '0';
    match.push(adultsOnly === activeFilters[filters.ADULTS_FILTER]);
  }
  if (activeFilters[filters.PROVIDER_FILTER]) {
    const providerID =
      movie[filters.PROVIDER_FILTER] && movie[filters.PROVIDER_FILTER].id.toString();
    match.push(providerID === activeFilters[filters.PROVIDER_FILTER]);
  }
  if (activeFilters[filters.ID_FILTER]) {
    match.push(filterMoviesByID(movie.id, activeFilters[filters.ID_FILTER]));
  }
  return match.every(val => {
    return val === true;
  });
}

function filterMoviesByLicence(licenceDate, filterType) {
  const hasDate = licenceDate !== null;
  const licenceIsActive = licenceDate > TODAY || licenceDate === TODAY;
  const timeToExpiration = licenceDate - TODAY;
  const closeToExpire =
    (timeToExpiration > 0 && timeToExpiration < DAYS_TO_EXPIRE) ||
    timeToExpiration === DAYS_TO_EXPIRE;

  if (filterType === 'active') {
    return licenceIsActive || closeToExpire || !hasDate;
  } else if (filterType === 'expiring') {
    return closeToExpire && hasDate;
  } else if (filterType === 'expired') {
    return !licenceIsActive && hasDate;
  }
}

function filterMoviesByID(movieID, filterType) {
  const { from: fromID, to: toID } = filterType;
  const isBigger = movieID > fromID || movieID === fromID;
  const isLower = movieID < toID || movieID === toID;
  if (fromID && toID) {
    return isBigger && isLower;
  } else if (fromID) {
    return isBigger;
  } else if (toID) {
    return isLower;
  }
}
