import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { API_AUTH_CLIENT_KEY, API_AUTH_TOKEN_KEY, API_AUTH_UID_KEY } from './app/common/consts';
import { logoutUser } from './app/auth/actions';

function getAuthenticationKeys(key = null) {
  const token = localStorage.getItem(API_AUTH_TOKEN_KEY);
  const client = localStorage.getItem(API_AUTH_CLIENT_KEY);
  const uid = localStorage.getItem(API_AUTH_UID_KEY);
  const keys = { token, client, uid };
  return key ? keys[key] : keys;
}

export function setAuthenticationKeys(token, client, uid) {
  localStorage.setItem(API_AUTH_TOKEN_KEY, token);
  localStorage.setItem(API_AUTH_CLIENT_KEY, client);
  localStorage.setItem(API_AUTH_UID_KEY, uid);
}

export function isUserAuthorized() {
  const { token, client, uid } = getAuthenticationKeys();
  return token && client && uid;
}

function setToken(res) {
  if (res.headers[API_AUTH_TOKEN_KEY]) {
    localStorage.setItem(API_AUTH_TOKEN_KEY, res.headers[API_AUTH_TOKEN_KEY]);
  }
}

export const axiosConfig = {
  init(store) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    axios.interceptors.request.use(
      request => {
        const { token, client, uid } = getAuthenticationKeys();
        if (token) {
          request.headers[API_AUTH_TOKEN_KEY] = token;
          request.headers[API_AUTH_CLIENT_KEY] = client;
          request.headers[API_AUTH_UID_KEY] = uid;
        }
        if (!(request.data instanceof FormData)) {
          request.data = decamelizeKeys(request.data);
        }
        return request;
      },
      error => Promise.reject(error)
    );

    axios.interceptors.response.use(
      response => {
        setToken(response);
        return camelizeKeys(response.data.data);
      },
      error => {
        setToken(error.response);
        console.log('Response error: ', error);
        if (error.response.status === 401) {
          store.dispatch(logoutUser());
        }
        return Promise.reject(error.response);
      }
    );

    // For staging
    if (process.env.REACT_APP_API_CREDENTIALS) {
      axios.defaults.headers.common['Authorization'] =
        'Basic ' + btoa(process.env.REACT_APP_API_CREDENTIALS);
    }

    if (!getAuthenticationKeys('token')) {
      localStorage.clear();
    }
  }
};
