import axios from 'axios';
import { createActionTypes } from '../../requestMiddleware';

export const FETCH_MOVIES = createActionTypes('[Movies] Fetch movies');
export function fetchMovies() {
  return {
    types: FETCH_MOVIES,
    request: () => axios.get('/movies')
  };
}

export const FETCH_MOVIE_PROVIDERS = createActionTypes('[Movies] Fetch movie provider');
export function fetchMovieProviders() {
  return {
    types: FETCH_MOVIE_PROVIDERS,
    request: () => axios.get('/movie_providers')
  };
}

export const SEND_MOVIES = createActionTypes('[Movies] Send movies');
export function sendMovies(params) {
  return {
    types: SEND_MOVIES,
    request: () => axios.post('/movies/synchronize', { movieIds: params })
  };
}

export const SEND_MOVIES_RESET = '[Movies] Send movies reset';
export function sendMoviesReset() {
  return function(dispatch) {
    dispatch({type: SEND_MOVIES_RESET})
  }
}