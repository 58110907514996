import React from 'react';

function UploadFileButton(props) {
  return (
    <React.Fragment>
      <input
        id={props.id}
        accept="image/*"
        className="d-none"
        type="file"
        onChange={e => props.upload(e.target.files[0])}
      />
      <label htmlFor={props.id} className={`mb-0 ${props.customClass}`}>
        {props.children}
      </label>
    </React.Fragment>
  );
}

export default UploadFileButton;