import { DELETE_NOTIFICATION, SHOW_NOTIFICATION } from '../actions/NotificationsActions';

let index;

const notifications = (state = [], { type, payload }) => {
  if (state.length === 0) {
    index = 0;
  }
  switch (type) {
    case SHOW_NOTIFICATION:
      index++;
      return [...state, ...[Object.assign(payload, { id: index })]];
    case DELETE_NOTIFICATION:
      return state.filter(notice => {
        return notice.id !== payload;
      });
    default:
      return state;
  }
};

export default notifications;
