import React, { Component } from 'react';
import shaka from 'shaka-player';
import {
  WAIT_FOR_PLAYING_TIME,
  DONE,
  IN_PROGRESS,
  NOT_STARTED,
  LOADING,
  CSVHeaders
} from './../const';
import { connect } from 'react-redux';
import { fetchMoviesToCheck } from './../actions';
import { CSVLink } from 'react-csv';

class CheckMovies extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      brokenVideos: [],
      status: NOT_STARTED,
      index: 0,
      errors: null
    };
  }

  start = () => {
    shaka.polyfill.installAll();
    // Check to see if the browser supports the basic APIs Shaka needs.
    if (shaka.Player.isBrowserSupported()) {
      // Everything looks good!
      this.initPlayer();
    } else {
      // This browser does not have the minimum set of APIs we need.
      console.error('Browser not supported!');
    }
  };

  async initPlayer() {
    let video = this.myRef.current;

    // Create a Player instance.
    const player = new shaka.Player(video);

    // Listen for error events.
    player.addEventListener('error', this.onErrorEvent);

    this.setState({
      status: IN_PROGRESS,
      brokenVideos: [],
      index: 0,
      errors: null
    });

    await this.testVideo(this.props.moviesToCheck.data, video, player);

    this.setState({
      status: DONE
    });
  }

  async testVideo(videoList, video, player) {
    let resolveVar;
    let timeOutVar;
    const waitForPlaying = item => {
      timeOutVar = setTimeout(() => {
        item.error = 'Unplayable';
        this.setState({
          index: this.state.index + 1,
          brokenVideos: [...this.state.brokenVideos, item]
        });
        player.unload();
        resolveVar();
      }, WAIT_FOR_PLAYING_TIME);
    };
    const loadVideo = item => {
      return new Promise(resolve => {
        resolveVar = resolve;
        player
          .load(item.url)
          .then(() => {
            waitForPlaying(item);
          })
          .catch(err => {
            item.error = 'Shaka player code: ' + err.code;
            this.setState({
              index: this.state.index + 1,
              brokenVideos: [...this.state.brokenVideos, item]
            });
            player.unload();
            resolveVar();
          }); // onError is executed if the asynchronous load fails.
      });
    };

    const listenerCallback = () => {
      this.setState({
        index: this.state.index + 1
      });
      player.unload();
      clearTimeout(timeOutVar);
      resolveVar();
    };

    video.addEventListener('playing', listenerCallback);
    for (const item of videoList) {
      await loadVideo(item);
    }
    video.removeEventListener('playing', listenerCallback);
    console.log('Done!');
  }

  sortById = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };

  renderBrokenVideos = () => {
    return (
      <div className="row card card-topline w-100">
        <div className="card-body">
          <h2 className="d-inline-block mb-2">Broken movies</h2>
          {!!this.state.brokenVideos.length && (
            <button className="btn btn-primary float-right position-relative">
              CSV
              <CSVLink
                key={JSON.stringify(this.state.brokenVideos)}
                filename="broken-movies.csv"
                data={this.state.brokenVideos}
                headers={CSVHeaders}
                className="fill-relative-parent"
              />
            </button>
          )}
          <div className="table-responsive-lg">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">File name</th>
                  <th scope="col">Format</th>
                  <th scope="col">Resolution</th>
                  <th scope="col" style={{ width: '50%' }}>
                    URL
                  </th>
                  <th scope="col">Error</th>
                </tr>
              </thead>
              <tbody>
                {this.state.brokenVideos.sort(this.sortById).map(movie => (
                  <tr key={movie.id}>
                    <td>{movie.id}</td>
                    <td>{movie.fileName}</td>
                    <td>{movie.format}</td>
                    <td>{movie.resolution}</td>
                    <td>{movie.url}</td>
                    <td>{movie.error}</td>
                  </tr>
                ))}
                {!this.state.brokenVideos.length && (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No broken videos
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError = error => {
    // Log the error.
    this.setState({ errors: error });
    console.error('Error code', error.code, 'object', error);
  };

  renderErrors = () => (
    <div className="text-left">
      <p className="text-danger">Error! Error details:</p>
      <ul>
        {Object.keys(this.state.errors).map(key => {
          if (
            typeof this.state.errors[key] === 'number' ||
            typeof this.state.errors[key] === 'string'
          ) {
            return (
              <li key={key}>
                {key}: {this.state.errors[key]}
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );

  handleCheck = () => {
    this.setState(
      {
        status: LOADING
      },
      () =>
        this.props.fetchMoviesToCheck().then(() => {
          this.start();
        })
    );
  };

  render() {
    const percent = this.props.moviesToCheck.data.length
      ? (this.state.index / this.props.moviesToCheck.data.length) * 100
      : 0;
    return (
      <div className="container check-movies mb-3">
        <div className="row card card-topline mb-3 w-100">
          <div className="col-sm-6 d-flex">
            <div className="d-flex w-100 align-items-center justify-content-center">
              <button
                className="btn btn-primary check-btn"
                disabled={this.state.status === IN_PROGRESS}
                onClick={this.handleCheck}
              >
                Chech movies
              </button>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="video-wrapper">
              <video
                ref={this.myRef}
                id="video"
                width="100%"
                poster="//shaka-player-demo.appspot.com/assets/poster.jpg"
                controls
                autoPlay
              />
            </div>
          </div>
          {this.state.errors && this.renderErrors()}
        </div>
        <div className="row card card-topline mb-3 w-100">
          <div className="card-body">
            <h2 className="d-inline-block mb-2">Status: {this.state.status}</h2>
            {this.state.status !== NOT_STARTED && (
              <React.Fragment>
                <p className="text-center">
                  {this.state.index} / {this.props.moviesToCheck.data.length}
                </p>
                <div className="loading-bar">
                  <span style={{ width: percent + '%' }}>&nbsp;</span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {this.renderBrokenVideos()}
      </div>
    );
  }
}

const mapStateToProps = ({ moviesToCheckReducer }) => {
  return {
    moviesToCheck: moviesToCheckReducer.moviesToCheck
  };
};

const mapDispatchToProps = {
  fetchMoviesToCheck
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckMovies);
