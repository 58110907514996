import React, { Component } from 'react';

const extension = '...';

export default class TablePagination extends Component {
  changePagination(currentPage, numberOfPages) {
    if (numberOfPages <= 1) return [];

    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    const pagesBefore = currentPage - delta;
    const pagesAfter = currentPage + delta;
    let l;

    for (let i = pagesBefore; i <= pagesAfter; i++) {
      if (i < numberOfPages && i >= 1) {
        range.push(i);
      }
    }
    range.push(numberOfPages);

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push(extension);
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  renderPaginationNav(type, page) {
    return (
      <li className={`page-item ${type}-page`}>
        <span className="page-link" onClick={() => this.props.onPageChange(page)} />
      </li>
    );
  }

  render() {
    const paginationList = this.changePagination(this.props.currentPage, this.props.totalPages);
    return (
      <ul className="pagination justify-content-center mt-2">
        {this.props.currentPage !== 1 ? this.renderPaginationNav('first', 1) : null}
        {this.props.currentPage !== 1
          ? this.renderPaginationNav('prev', this.props.currentPage - 1)
          : null}
        {paginationList.map((page, index) => {
          const classes = `page-item ${page === this.props.currentPage ? 'active' : ''} ${
            page === extension ? 'disabled' : ''
          }`;
          return (
            <li key={index} className={classes}>
              <span
                className="page-link"
                onClick={() =>
                  page !== this.props.currentPage && page !== extension
                    ? this.props.onPageChange(page)
                    : null
                }
              >
                {page}
              </span>
            </li>
          );
        })}
        {this.props.currentPage !== this.props.totalPages && this.props.totalPages
          ? this.renderPaginationNav('next', this.props.currentPage + 1)
          : null}
        {this.props.currentPage !== this.props.totalPages && this.props.totalPages
          ? this.renderPaginationNav('last', this.props.totalPages)
          : null}
      </ul>
    );
  }
}
