import React from 'react';
import { Form } from 'formik';
import {
  renderAdditionalInfo,
  renderDatePicker,
  renderLanguages,
  renderProviders,
  renderInput,
  renderIMDB,
  renderTextarea
} from '../helpers';

const MovieDetails = props => {
  const { handleSubmit } = props;
  const renderInputDefault = (name, key) => renderInput(props, name, key);
  const renderTextareaDefault = (name, key) => renderTextarea(props, name, key);
  const renderDatePickerDefault = (name, key) => renderDatePicker({ props, name, key });
  const renderAdditionalInfoDefault = () => renderAdditionalInfo(props);
  const renderIMDBDefault = fillDetails => renderIMDB(props, fillDetails);
  const renderProvidersDefault = () =>
    renderProviders({
      props,
      providers: props.movieProviders
    });
  const renderLanguagesDefault = () =>
    renderLanguages({
      props,
      languages: props.languages
    });
  const fillDetails =
    props.movie && props.movie.imdbId && props.movie.imdbId.length ? props.imdbUpdateMovie : null;
  return (
    <React.Fragment>
      <button className="btn btn-primary float-right" type="submit" onClick={handleSubmit}>
        <span>Save</span>
      </button>
      <Form onSubmit={handleSubmit} className="d-flex flex-column flex-fill pt-2">
        {renderProvidersDefault()}
        {renderInputDefault('Title', 'title')}
        {renderIMDBDefault(fillDetails)}
        {renderTextareaDefault('Description', 'description')}
        {renderInputDefault('Year', 'year')}
        {renderDatePickerDefault('Release date', 'releaseDate')}
        {renderInputDefault('Director', 'director')}
        {renderInputDefault('Cast', 'cast')}
        {renderInputDefault('Country', 'country')}
        {renderLanguagesDefault()}
        {renderInputDefault('Duration', 'duration')}
        {renderInputDefault('Rating', 'rating')}
        {renderAdditionalInfoDefault()}
        {renderDatePickerDefault('License expiration date', 'licenseExpireAt')}
      </Form>
    </React.Fragment>
  );
};

export default MovieDetails;
