import {
  FETCH_SINGLE_MOVIE,
  CLEAR_SINGE_MOVIE,
  IMDB_UPDATE_MOVIE,
  CREATE_IMAGE, DELETE_IMAGE
} from './actions';
import { IMAGE_TYPE } from './consts';

const initialState = {
  singleMovie: {
    data: null,
    loading: false,
    uploading: false
  }
};

const singleMovieReducer = (state = initialState, { type, payload, requestParams }) => {
  switch (type) {
    case FETCH_SINGLE_MOVIE.REQUEST:
    case IMDB_UPDATE_MOVIE.REQUEST:
      return { ...state, singleMovie: { data: null, loading: true } };

    case FETCH_SINGLE_MOVIE.SUCCESS:
    case IMDB_UPDATE_MOVIE.SUCCESS:
      return { ...state, singleMovie: { data: payload, loading: false } };

    case FETCH_SINGLE_MOVIE.ERROR:
    case IMDB_UPDATE_MOVIE.ERROR:
    case CLEAR_SINGE_MOVIE:
      return { ...state, singleMovie: initialState.singleMovie };

    case CREATE_IMAGE.REQUEST:
      return { ...state, singleMovie: { ...state.singleMovie, uploading: true }};

    case CREATE_IMAGE.SUCCESS:
      const key = payload && payload.type ?
        dataKeyForImageTypeMap.get(decapitalizeFirstLetter(payload.type)) : null;

      if (key) {
        return {
          ...state,
          singleMovie: {
            ...state.singleMovie,
            uploading: false,
            data: {
              ...state.singleMovie.data,
              [key]: [...state.singleMovie.data[key], payload]
            }
          }
        }
      } else {
        return state;
      }

    case CREATE_IMAGE.ERROR:
      return { ...state, singleMovie: { ...state.singleMovie, uploading: false }};

    case DELETE_IMAGE.REQUEST:
      return updateFileDeletingStatus(state, payload, {isDeleting: true});

    case DELETE_IMAGE.SUCCESS:
      return updateFileDeletingStatus(state, requestParams, {isDeleting: false, isDeleted: true});

    case DELETE_IMAGE.ERROR:
      return updateFileDeletingStatus(state, requestParams, {isDeleting: false});

    default:
      return state;
  }
};

export default singleMovieReducer;

function updateFileDeletingStatus(state, payload, options) {
  const key = dataKeyForImageTypeMap.get(payload.imageType);
  const {isDeleting, isDeleted} = options;
  return {
    ...state,
    singleMovie: {
      ...state.singleMovie,
      data: {
        ...state.singleMovie.data,
        [key]: state.singleMovie.data[key].map(item => {
          let deleted = isDeleted ? {deletedAt: new Date()} : {};
          return item.id === payload.id ?
            {...item, deleting: isDeleting, ...deleted} :
            item;
        })
      }
    }
  };
}

function decapitalizeFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

const dataKeyForImageTypeMap = new Map([
  [IMAGE_TYPE.cover, 'covers'],
  [IMAGE_TYPE.screen, 'screens'],
  ['promoCover', 'promoCovers'],
  [IMAGE_TYPE.promoCover, 'promoCovers']
]);